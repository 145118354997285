import React, { useState, useEffect } from "react";
import useBounceInUpAnimation from "../../hooks/useBounceInUpAnimation";
import FeaturesIcon from "../../assets/svg/features-icon";
import LightningIcon from "../../assets/svg/lightning-icon";
import DropdownIcon from "../../assets/svg/dropdown-icon";
import NavigationButton from "../../atoms/navigation-button/NavigationButton";
import NavigationButtonDropDown from "../../atoms/navigation-button/NavigationButtonDropDown";
import WaivlengthLogo from "../../assets/svg/waivlength-logo";
import "./Navigation.scss";
import FutureFeaturesIcon from "../../assets/svg/future-features-icon";
import ContactUsIcon from "../../assets/svg/contact-us-icon";
import CommunitiesIcon from "../../assets/svg/communities-icon";
import { useDialog } from "../../contexts/DialogContext";
import CloseIcon from "../../assets/svg/close-icon";
import MoonIcon from "../../assets/svg/moon-icon";
import SunIcon from "../../assets/svg/sun-icon";
import { useTheme } from "../../contexts/ThemeContext";

const Navigation = () => {
  const [isVisible, setIsVisible] = useState(false);
  const closeDialog = () => setIsVisible(false);
  const { theme, toggleTheme } = useTheme();
  const isDarkTheme = theme === "dark-theme";
  const [scrollPositions, setScrollPositions] = useState({
    features: 2648,
    communities: 4832,
    comingSoon: 7835,
    contactUs: 9961,
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setScrollPositions({
        features: 2648 + (width < 1080 ? 130 : 20),
        communities: 4832 + (width < 1080 ? 250 : 50),
        comingSoon: 7835 + (width < 1080 ? 380 : 0),
        contactUs: 9961 + (width < 1080 ? 800 : 100),
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { style: landingPageStyle } = useBounceInUpAnimation(
    0,
    "translate(0px, 0px)",
    "translate(0px, 0px)",
    "translate(0px, 0px)",
    2000,
    800
  );
  const { toggleDialog } = useDialog();

  return (
    <div className="landing-page-navigation" style={landingPageStyle}>
      <div className="landing-page-navigation-logo-container">
        <WaivlengthLogo
          fill="var(--color-bg-secondary)"
          height={26}
          width={36}
        />
        <span className="landing-page-nav-logo-text">waivlength</span>
      </div>
      <div className="landing-page-navigation-sections">
        <NavigationButton
          buttonText="Features"
          IconComponent={FeaturesIcon}
          scrollPosition={scrollPositions.features}
        />
        <NavigationButton
          buttonText="Communities"
          IconComponent={CommunitiesIcon}
          scrollPosition={scrollPositions.communities}
        />
        <NavigationButton
          buttonText="Coming Soon"
          IconComponent={FutureFeaturesIcon}
          scrollPosition={scrollPositions.comingSoon}
        />
        <NavigationButton
          buttonText="Contact Us"
          IconComponent={ContactUsIcon}
          scrollPosition={scrollPositions.contactUs}
        />
      </div>
      <div className="landing-page-theme-button" onClick={toggleTheme}>
        {isDarkTheme ? (
          <SunIcon height={20} width={20} fill="var(--color-bg-secondary)" />
        ) : (
          <MoonIcon
            stroke="var(--color-bg-secondary)"
            strokeWidth={3}
            height={16}
            width={16}
          />
        )}
      </div>
      <button
        className="landing-page-navigation-signup-button-container"
        onClick={toggleDialog}
      >
        <LightningIcon
          stroke="var(--color-text-secondary)"
          strokeWidth={3}
          height={16}
          width={16}
        />
        <span className="landing-page-nav-signup-text">
          Sign up for early access
        </span>
        <span className="landing-page-nav-signup-text-shortened">Sign up</span>
      </button>
      <button
        className="landing-page-drop-down-menu"
        onClick={() => setIsVisible(!isVisible)}
        style={{ position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            left: "78%",
            top: "50%",
            transform: isVisible
              ? "translate(-50%, -50%) scale(0)"
              : "translate(-50%, -50%) scale(1)",
            opacity: isVisible ? 0 : 1,
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            alignItems: "center",
            justifycontent: "center",
            height: "100%",
            width: "100%",
            display: "flex",
          }}
        >
          <DropdownIcon
            stroke="var(--color-bg-secondary)"
            strokeWidth={3}
            height={16}
            width={16}
          />
        </div>
        <div
          style={{
            position: "absolute",
            left: "78%",
            top: "50%",
            transform: isVisible
              ? "translate(-50%, -50%) scale(1)"
              : "translate(-50%, -50%) scale(0)",
            opacity: isVisible ? 1 : 0,
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            alignItems: "center",
            justifycontent: "center",
            height: "100%",
            width: "100%",
            display: "flex",
          }}
        >
          <CloseIcon fill="var(--color-bg-secondary)" height={16} width={16} />
        </div>
      </button>
      <div
        className={`navigation-dialog-background ${isVisible ? "visible" : ""}`}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            setIsVisible(false);
          }
        }}
      >
        <div className="navigation-dialog-content">
          <NavigationButtonDropDown
            buttonText="Features"
            IconComponent={FeaturesIcon}
            scrollPosition={scrollPositions.features}
            onClick={closeDialog}
          />
          <NavigationButtonDropDown
            buttonText="Communities"
            IconComponent={CommunitiesIcon}
            scrollPosition={scrollPositions.communities}
            onClick={closeDialog}
          />
          <NavigationButtonDropDown
            buttonText="Coming Soon"
            IconComponent={FutureFeaturesIcon}
            scrollPosition={scrollPositions.comingSoon}
            onClick={closeDialog}
          />
          <NavigationButtonDropDown
            buttonText="Contact Us"
            IconComponent={ContactUsIcon}
            scrollPosition={scrollPositions.contactUs}
            onClick={closeDialog}
          />
        </div>
      </div>
    </div>
  );
};

export default Navigation;
